<template>
  <div>
    <div style="display: flex;">
      <el-input v-model="searchText" placeholder="Поиск по имени" :loading="loading" style="width: 22.22222222222222em;" size="large" class="mb4" @keydown.enter="getRequestsList(); pageNumber=1">
        <template #prefix>
          <el-icon>
            <Search />
          </el-icon>
        </template>
      </el-input>
      <el-button size="large" type="primary" class="ml3" @click="getRequestsList(); pageNumber=1">
        <el-icon>
          <Search />
        </el-icon>
      </el-button>
    </div>

    <el-table style="width:100%" :data="items" :default-sort="{ prop: 'position', order: 'descending' }">
      <el-table-column prop="createdDate" label="Дата">
        <template #default="scope">
          <div style="display: flex; flex-direction: column;">
            <div>
              {{ $moment(scope.row.createdDate ).format('HH:MM') }}
            </div>
            <div>
              {{ $moment(scope.row.createdDate ).format('YYYY.MM.DD') }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sender.pseudonym" label="Артист" />
      <el-table-column prop="sender.socialMedia" label="Способ вывода" width="300px" />
      <el-table-column prop="amount" label="Вывод" width="98px" />
      <el-table-column prop="amount" label="Одобрить" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;" @click="approveRequest(scope.row.id)">
            <div style="border:1px rgba(255,255,255,0.3) solid; width:1.9444444444444444em; height:1.9444444444444444em; border-radius: 0.5555555555555556em; display: flex; align-items: center; justify-content: center;">
              <el-icon v-if="scope.row.status.value === 6" :size="27" color="#3290FF">
                <Select />
              </el-icon>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Отклонить" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;" @click="declineRequest(scope.row.id)">
            <div style="border:1px rgba(255,255,255,0.3) solid; width:1.9444444444444444em; height:1.9444444444444444em; border-radius: 0.5555555555555556em; display: flex; align-items: center; justify-content: center">
              <el-icon v-if="scope.row.status.value === 5" :size="27">
                <CloseBold />
              </el-icon>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-size="pageSize"
      :page-count="totalPages"
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="pageChange"
    />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  name: 'FinanceRequestTable',
  // props: {
  //   items: Array,
  // },
  data: () => ({
    searchText: '',
    items: [],
    totalPages: 0,
    pageSize: 10,
    totalCount: 0,
    pageNumber: 1,
    rates: [],
  }),
  mounted() {
    this.getRequestsList();
  },
  methods: {

    pageChange(page) {
      this.pageNumber = page;
      this.getRequestsList();
    },
    async approveRequest(id) {
      console.log(id);
      const params = {

      };
      this.loading = true;
      try {
        await this.$axios.post(`/withdrawal-request/accept-by-withdrawal-request-id/${id}`, params);
        this.getRequestsList();
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async declineRequest(id) {
      const params = {

      };
      this.loading = true;
      try {
        await this.$axios.post(`/withdrawal-request/deny-by-withdrawal-request-id/${id}`, params);
        this.getRequestsList();
      } catch (e) {
        ElMessage({ message: e.response.data[0], type: 'error' });
      } finally {
        this.loading = false;
      }
    },
    async getRequestsList() {
      let query = '';
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.searchText) {
        query = `?artistName=${this.searchText}`;
      }
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/withdrawal-request/get-withdrawal-requests-by-status${query}`, params);
        this.totalPages = data.items.totalPages;
        this.totalCount = data.items.totalCount;
        this.items = data.items.items;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
