<template>
  <div>
    <FinanceRequestTable />
  </div>
</template>

<script>
import FinanceRequestTable from '@/components/tables/FinanceRequestTable.vue';

export default {
  name: 'FinanceRequestList',
  components: {
    FinanceRequestTable,
  },
  data: () => ({

  }),
};
</script>
